import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  TextBox,
  BlogSlider,
  Breadcrumbs,
  BannerLinks
} from '../../components'

import BannerImage from '../../assets/projektuebersicht.jpg'

import cards from '../../components/BlogSlider/DefaultCards'
import boxes from '../../components/BannerLinks/DefaultBox'

import { generateBreadcrumbsLinks } from '../../utils/index'

const ExpertenScreening = () => {
  const links = generateBreadcrumbsLinks('/blog/experten-screening', 'Experten screening')
  return (
    <Layout>
      <Helmet
        title="Experten Screening für Usability"
        meta={[
          {
            name: 'description',
            content:
              'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.'
          },
          {
            name: 'keywords',
            content:
              'Experten Screening für Usability holzweg, Blog holzweg, Innsbruck, holzweg, Blog, Usability, Screening, Test, Website Test'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Neugierig bleiben
            <br />
            Neues lernen
          </span>
        }
      />
      <Breadcrumbs
          backLinkAlias=' '
          links={links}
        />
      <TextBox
        title="Experten Screening für Usability"
        text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut."
      />
      <BlogSlider cards={cards} singleRow={true} />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default ExpertenScreening
